// This is called a "splat route" and as it's in the root `/app/routes/`
// directory, it's a catchall. If no other routes match, this one will and we
// can know that the user is hitting a URL that doesn't exist. By throwing a
// 404 from the loader, we can force the error boundary to render which will
// ensure the user gets the right status code and we can display a nicer error
// message for them than the Remix and/or browser default.

import { useLocation } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { LocaleLink } from '#app/components/locale-link.tsx'
import { useCurrentLanguage } from '#app/hooks/use-current-language.tsx'

export async function loader() {
	throw new Response('Not found', { status: 404 })
}

export default function NotFound() {
	// due to the loader, this component will never be rendered, but we'll return
	// the error boundary just in case.
	return <ErrorBoundary />
}

export function ErrorBoundary() {
	const location = useLocation()
	const { t } = useTranslation(['common'])
	const currentLanguage = useCurrentLanguage()
	return (
		<GeneralErrorBoundary
			statusHandlers={{
				404: () => (
					<main className="flex h-full flex-col items-center justify-center px-4 px-6 py-24 sm:py-32 lg:px-8">
						<div className="text-center">
							<p className="text-base font-semibold text-primary-400">404</p>
							<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
								{t('404.notFound')}
							</h1>
							<p className="mt-6 text-base leading-7 text-gray-600">
								{t('404.notFoundSub')}
							</p>
							<pre className="whitespace-pre-wrap break-all text-body-lg">
								{location.pathname}
							</pre>
							<div className="mt-10 flex items-center justify-center gap-x-6">
								<LocaleLink
									lang={currentLanguage}
									route="homeRoot"
									className="rounded-md bg-primary-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-300"
								>
									{t('404.goHome')}
								</LocaleLink>
							</div>
						</div>
					</main>
				),
			}}
		/>
	)
}
